import PropTypes from 'prop-types';
import Script from 'next/script';

import '../styles/app.css';

const FrontpageApp = ({ Component, pageProps }) => (
    <>
        <Component {...pageProps} />
        <Script
            src="https://cdn.trackjs.com/agent/v3/latest/t.js"
            onLoad={() => {
                window.TrackJS?.install?.({
                    token: '74bb3e28a1604fd2b28c72c568722d21',
                    application: 'finn-travel-frontpage',
                    version: `'${process.env.NEXT_PUBLIC_GIT_COMMIT_SHA}'`,
                    enabled: location.host.indexOf('local') !== 0 && location.host.indexOf('127.0.0') !== 0,
                });
            }}
            crossOrigin=""
        />
        <Script
            id="gtm-init"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,l){
                      w[l]=w[l]||[];
                      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    })(window,'dataLayer');
                `,
            }}
        />
        <Script id="gtm" src="https://www.googletagmanager.com/gtm.js?id=GTM-TP67TQBT" />
        <Script id="pulse-to-gtm" src="https://assets.finn.no/pkg/pulse-event-listener/v1/index.js" />
    </>
);

FrontpageApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default FrontpageApp;
